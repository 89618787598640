@import "fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  @apply text-5xl font-bold;
}

h2 {
  @apply text-4xl font-bold;
}

h3 {
  @apply text-3xl font-bold;
}

h4 {
  @apply text-2xl font-bold;
}

.btn {
  @apply group flex items-center rounded-full text-xl font-bold px-8 py-4
}

.btn-lg {
  @apply btn px-11 py-5
}

.btn-wide {
  @apply btn px-24 py-4
}

.btn-block {
  @apply block justify-center;
}

.btn-brand-yellow {
  @apply hover:bg-brand-yellow-300 bg-brand-yellow text-white;
}

.btn-white {
  @apply hover:bg-brand-gray-50 bg-white text-brand-gray;
}
